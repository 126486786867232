<template>
  <div :class="containerClass">
    <page-header
      :title="this.$route.params.id ? 'Edit Resource' : 'Add Resource'"
      :breadcrumb="breadcrumb"
      :container-class="null"
      class="mb-32pt"
    />

    <page-separator title="Resource Details" />

    <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
      <!-- Add when super admin work is done -->
      <!-- <b-form-group
        v-if="getLoggedInUser.role === USER_ROLES.SUPER_ADMIN"
        :label="$t('schoolMsgs.school')"
        label-for="school"
        label-class="form-label"
      >
        <v-select
          id="organization"
          class="form-control v-select-custom"
          label="name"
          v-model="resource.organization"
          :reduce="organization => organization.id"
          placeholder="Select organization"
          :options="allSchools"
          :loading="areSchoolsLoading"
          :class="!$v.resource.organization.required && $v.resource.school.$dirty ? 'form-control is-invalid' : ''"
        >
          <template #search="{ attributes, events }">
            <input class="vs__search" :required="!resource.school" v-bind="attributes" v-on="events" />
          </template>

          <template slot="option" slot-scope="option">
            <div class="d-flex align-items-center">
              <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside" size="xs">
                <b-img :src="option.logo_url" class="img-fluid" width="20" alt="Logo" v-if="option.logo_url" />
                <i class="fas fa-university fa-lg" v-else></i>
              </fmv-avatar>
              <span>{{ option.name }}</span>
            </div>
          </template>
          <template slot="selected-option" slot-scope="option">
            {{ option.name }}
          </template>
          <b-form-invalid-feedback :state="!$v.resource.school.required && $v.resource.school.$dirty ? false : null"
            >This field is required.</b-form-invalid-feedback
          >
        </v-select>
      </b-form-group> -->
      <b-form-group label="Image" label-for="image" label-class="form-label" class="row-align-items-center">
        <b-media class="align-items-center" vertical-align="center">
          <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
            <span v-if="isLogoUploading">...</span>
            <b-img :src="resource.image_url" class="img-fluid" width="40" alt="Logo" v-else-if="resource.image_url" />
            <i class="material-icons" v-else>vignette</i>
          </fmv-avatar>

          <image-uploader
            @image="e => setImage(e, 'resource_images')"
            :isUploading="isLogoUploading"
            :isRequired="!resource.image_url"
            placeholder="Select resource's image"
          />
        </b-media>
      </b-form-group>
      <b-form-group label="Title" label-for="title" label-class="form-label">
        <b-form-input id="title" placeholder="Enter resource's title" v-model="resource.title" required />
      </b-form-group>

      <b-form-group label="Description" label-for="short-description" label-class="form-label">
        <b-form-textarea
          id="short-description"
          placeholder="Enter a description for the resource"
          :rows="5"
          v-model="resource.shortDesc"
          required
        />
      </b-form-group>

      <b-form-group label="CTA Button Text" label-for="external_text" label-class="form-label">
        <b-form-input id="external_text" placeholder="Enter button's text" v-model="resource.btnText" required />
      </b-form-group>

      <b-form-group label="CTA Button Link" label-for="external_link" label-class="form-label">
        <b-form-input
          id="external_link"
          placeholder="Enter button's link"
          v-model="resource.externalLink"
          :required="!resource.isEnabled"
        />
      </b-form-group>

      <b-form-group label-class="form-label">
        <b-form-checkbox id="detail" v-model="resource.isEnabled" name="check-button" switch>
          Enable detail page?
        </b-form-checkbox>
      </b-form-group>

      <b-form-group v-if="resource.isEnabled" label="Details" label-for="description" label-class="form-label">
        <editor
          :init="{
            height: 400,
            menubar: 'edit view insert format tools table help',
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount',
            ],
            toolbar:
              'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help',
          }"
          v-model="resource.description"
          placeholder="Enter resource's details."
        />
      </b-form-group>

      <div class="row">
        <div class="col-6">
          <b-form-group label-class="form-label">
            <b-form-checkbox id="visible" v-model="resource.isVisible" name="check-button" switch>
              Is Resource visible?
            </b-form-checkbox>
          </b-form-group>
        </div>
        <div class="col-6">
          <b-form-group label-class="form-label">
            <b-form-checkbox id="featured" v-model="resource.isFeatured" name="check-button" switch>
              Is Resource featured?
              <i
                class="material-icons icon-16pt clickable-item text-muted mb-1"
                v-b-popover.hover.top="'Featured resources are shown above default resources.'"
                >info</i
              >
            </b-form-checkbox>
          </b-form-group>
        </div>
      </div>

      <b-btn variant="primary" :disabled="isFormLoading" style="width: 150px" type="submit" class="btn-normal">
        <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
        <span v-else>{{ $route.params.id ? 'Update' : 'Post' }}</span>
      </b-btn>
    </b-form>
  </div>
</template>
<script>
import PageHeader from '@/components/Ui/PageHeader.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import Page from '../../../components/Page.vue';
import { mapGetters, mapActions } from 'vuex';
import { FmvAvatar } from 'fmv-avatar';
import ImageUploader from '@/components/ImageUploader.vue';
import axios from 'axios';
import { USER_ROLES } from '@/common/constants';
import Editor from '@tinymce/tinymce-vue';
import { requiredIf } from 'vuelidate/lib/validators';

export default {
  components: { PageHeader, PageSeparator, FmvAvatar, ImageUploader, Editor },
  extends: Page,
  data() {
    return {
      title: this.$route.params.id ? 'Edit Resource' : 'Add Resource',
      tinemceApiKey: process.env.VUE_APP_TINEMCE_API_KEY,

      resource: {
        organization: null,
        image_url: '',
        title: '',
        shortDesc: '',
        description: '',
        isVisible: true,
        isEnabled: false,
        isFeatured: false,
        externalLink: '',
        btnText: '',
      },
      USER_ROLES,
      isLogoUploading: false,
      isLoading: false,
      uploadFile: { resource_images: null },
      uploadPercent: { resource_images: 0 },
      uploadCancelTokenSource: { resource_images: null },
      isFormLoading: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        {
          text: 'Resources',
          to: {
            name: 'manage-org-resources',
          },
          //   to: {
          //     name: this.getLoggedInUser.role === USER_ROLES.SUPER_ADMIN ? 'manage-resources' : 'school-manage-resources',
          //   },
        },
        {
          text: this.$route.params.id ? 'Edit Resource' : 'Add Resource',
          active: true,
        },
      ];
    },
  },
  validations() {
    return {
      resource: {
        organization: {
          required: requiredIf(() => this.getLoggedInUser.role === USER_ROLES.SUPER_ADMIN),
        },
      },
    };
  },
  methods: {
    ...mapActions('s3Upload', ['uploadToPresignedUrl']),
    ...mapActions('organization', [
      'getOrgImageUploadPresignedUrl',
      'updateOrgResource',
      'createOrgResource',
      'getOrganizationResource',
    ]),
    setImage(file, image) {
      this.uploadFile[image] = file;
      this.uploadImage(image);
    },
    async uploadImage(uploadType) {
      this.uploadCancelTokenSource[uploadType] = axios.CancelToken.source();
      this.isLogoUploading = uploadType === 'resource_images';
      try {
        const urlResp = await this.getOrgImageUploadPresignedUrl({
          file_name: this.uploadFile[uploadType].name,
          content_type: this.uploadFile[uploadType].type,
        });
        await this.uploadToPresignedUrl({
          url: urlResp.upload_url,
          file: this.uploadFile[uploadType],
          config: {
            onUploadProgress: function(progressEvent) {
              this.uploadPercent[uploadType] = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            }.bind(this),
            cancelToken: this.uploadCancelTokenSource[uploadType].token,
          },
        });
        if (uploadType === 'resource_images') {
          this.resource.image_url = urlResp.upload_url.split('?')[0];
        }
      } catch (error) {
        this.uploadFile[uploadType] = null;
      }

      this.uploadCancelTokenSource[uploadType] = null;
      this.uploadPercent[uploadType] = 0;

      if (uploadType === 'resource_images') this.isLogoUploading = false;
    },
    async onSubmit() {
      this.isLoading = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          const data = {
            title: this.resource.title,
            image_url: this.resource.image_url,
            short_description: this.resource.shortDesc,
            description: this.resource.description,
            is_featured: this.resource.isFeatured,
            is_visible: this.resource.isVisible,
            enbl_detail_pg: this.resource.isEnabled,
            external_link: this.resource.externalLink,
            cta_btn_text: this.resource.btnText,
            organization: this.getLoggedInUser.linked_entity.id,
            //   this.getLoggedInUser.role === USER_ROLES.SUPER_ADMIN
            //     ? this.resource.school
            //     : this.getLoggedInUser.linked_entity.id,
          };
          if (this.$route.params.id) {
            await this.updateOrgResource({
              id: this.$route.params.id,
              data,
            });
            this.makeToast({ variant: 'success', msg: 'Resource Updated Successfully!' });
          } else {
            await this.createOrgResource(data);
            this.makeToast({ variant: 'success', msg: 'Resource Added Successfully!' });
          }
          setTimeout(
            () =>
              this.$router.push({
                name: 'manage-org-resources',
                //   this.getLoggedInUser.role === USER_ROLES.SUPER_ADMIN ? 'manage-resources' : 'manage-org-resources',
              }),
            250,
          );
        } catch (_err) {
          this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        }
      } else {
        this.makeToast({ variant: 'danger', msg: 'Please fill all fields correctly.' });
      }
      this.isLoading = false;
    },
  },
  async mounted() {
    this.isLoading = true;
    //TODO super admin work
    // this.fetchSchools();
    try {
      if (this.$route.params.id) {
        const res = await this.getOrganizationResource(this.$route.params.id);

        this.resource.organization = res.data.organization;
        this.resource.image_url = res.data.image_url;
        this.resource.title = res.data.title;
        this.resource.shortDesc = res.data.short_description;
        this.resource.description = res.data.description;
        this.resource.isVisible = res.data.is_visible;
        this.resource.isFeatured = res.data.is_featured;
        this.resource.isEnabled = res.data.enbl_detail_pg;
        this.resource.externalLink = res.data.external_link;
        this.resource.btnText = res.data.cta_btn_text;
      }
    } catch (e) {
      this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      this.$router.push({
        name: 'manage-org-resources',
        // this.getLoggedInUser.role === USER_ROLES.SUPER_ADMIN ? 'manage-resources' : 'manage-org-resources',
      });
    }

    this.isLoading = false;
  },
};
</script>
