<template>
  <div :class="containerClass">
    <page-header
      title="Configure Basic Website Settings"
      :container-class="null"
      class="mb-16pt"
      :info-text="
        getLoggedInUser.role_type === USER_ROLE_TYPES.ORGANIZATION
          ? `Configure basic structure of your site, including Navbar, Footer and Meta Description.`
          : ''
      "
    />

    <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
      <b-form-group
        label="Organization"
        label-for="org"
        label-class="form-label"
        v-if="get(getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN"
      >
        <v-select
          id="org"
          class="form-control v-select-custom"
          label="name"
          v-model="org"
          :reduce="org => org.id"
          placeholder="Select Organization"
          :options="allOrgs"
          :loading="areOrgsLoading"
          @input="prefillLogo(org)"
        >
          <template #search="{ attributes, events }">
            <input class="vs__search" :required="!org" v-bind="attributes" v-on="events" />
          </template>

          <template slot="option" slot-scope="option">
            <div class="d-flex align-items-center">
              <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside" size="xs">
                <b-img :src="option.logo_url" class="img-fluid" width="20" alt="Logo" v-if="option.logo_url" />
                <i class="fas fa-university fa-lg" v-else></i>
              </fmv-avatar>
              <span>{{ option.name }}</span>
            </div>
          </template>
          <template slot="selected-option" slot-scope="option">
            {{ option.name }}
          </template>
        </v-select>
        <b-form-invalid-feedback :state="!$v.org.required && $v.org.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
        >
      </b-form-group>

      <page-separator title="Website Scheme" />
      <b-form-group label="Font (Header + Body)" label-for="font" label-class="form-label">
        <v-select
          id="font"
          class="form-control v-select-custom"
          label="text"
          v-model="font"
          :reduce="font => font.value"
          placeholder="Select Header And Body Font"
          :options="allFonts"
          :clearable="false"
        >
          <template #search="{ attributes, events }">
            <input class="vs__search" v-bind="attributes" v-on="events" />
          </template>

          <template slot="option" slot-scope="option">
            <div class="d-flex align-items-center">
              <span style="font-size: 14px">{{ option.text }}</span>
            </div>
          </template>
          <template slot="selected-option" slot-scope="option">
            <div class="d-flex align-items-center">
              <span style="font-size: 14px">{{ option.text }}</span>
            </div>
          </template>
        </v-select>
      </b-form-group>

      <page-separator title="Website Data" />
      <b-form-group label="Show Training Providers on Site?" label-class="form-label pb-0">
        <div class="mb-2">
          <small>
            This controls the visibility of all training providers info and related links including upcoming
            classes.</small
          >
        </div>
        <b-form-radio-group
          id="show_schls_on_site"
          v-model="show_schls_on_site"
          :options="showTrainProvidersOptions"
          @change="onShowSchoolsChange"
        >
        </b-form-radio-group>
      </b-form-group>

      <b-form-group label="Show Trainings" label-class="form-label">
        <b-form-radio-group
          id="radios1"
          v-model="sponsored_programs"
          :options="options"
          name="radioOptions"
          @change="openConfirmModal"
        >
        </b-form-radio-group>
      </b-form-group>

      <page-separator title="Website Navbar" />

      <b-form-group label="Logo" label-for="image" label-class="form-label" class="row-align-items-center">
        <b-media class="align-items-center" vertical-align="center">
          <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
            <span v-if="isImgUploading">...</span>
            <b-img
              :src="basicConfig.logo_url"
              class="img-fluid"
              width="40"
              alt="Image"
              v-else-if="basicConfig.logo_url"
            />
            <i class="fas fa-university fa-lg" v-else></i>
          </fmv-avatar>

          <image-uploader
            @image="e => setImage(e, 'image')"
            :isUploading="isImgUploading"
            :isRequired="false"
            placeholder="Select Logo"
          />
        </b-media>
      </b-form-group>

      <b-form-group label="Navbar Menu" label-class="form-label">
        <draggable class="draggable-cursor" @start="dragging = true" @end="updateOrder" :list="basicConfig.navMenu">
          <b-form-group v-for="(menu, index) in basicConfig.navMenu" :key="`${menu}-${index}`" label-class="form-label">
            <div
              v-if="['careers', 'find_training'].includes(menu.name)"
              class="card card-lg overlay--primary-dodger-blue mb-1"
            >
              <div class="card-body d-flex flex-column">
                <div class="d-flex align-items-center">
                  <div class="col-8 col-md-8 my-2 d-flex">
                    <i class="fas fa-bars icon-16pt mr-3"></i>
                    <!-- <span v-if="get(getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN">
                      {{ menu.title }}
                    </span> -->
                    <!-- v-else -->
                    <editable-label
                      :inputLabel="menu.title"
                      v-model="menu.title"
                      @saveLabel="changeTitle(basicConfig.navMenu)"
                    />
                  </div>

                  <div class="col-4 col-md-4 d-flex justify-content-end">
                    <b-form-checkbox class="ml-auto" v-model="menu.is_enable" name="check-button" switch>
                      Enabled?
                    </b-form-checkbox>
                  </div>
                </div>

                <div
                  v-for="(sub_menu, aboutIndex) in menu.sub_menus"
                  :key="`${sub_menu}-${aboutIndex}`"
                  class="card-body d-flex flex-column mt-2"
                >
                  <div class="d-flex align-items-center px-4-lg">
                    <div class="col-6 col-md-8">
                      <!-- <i class="fas fa-bars icon-16pt mr-3"></i> -->
                      <!-- {{ $t(`navBar.${sub_menu.name}`) }} -->

                      <!-- <editable-label
                        :inputLabel="sub_menus.name"
                        v-model="sub_menus.name"
                        @saveLabel="changeTitle(sub_menus.name)"
                      /> -->
                      <!-- {{ sub_menu.title }} -->

                      <editable-label
                        :inputLabel="sub_menu.title"
                        v-model="sub_menu.title"
                        @saveLabel="changeTitle(basicConfig.navMenu)"
                      />
                    </div>
                    <div class="col-6 col-md-4 d-flex justify-content-end align-items-center">
                      <md-icon
                        v-b-popover.hover.top
                        :title="subMenuItemStatus[sub_menu.name].helpText"
                        class="icon-16pt text-gray mr-2"
                        v-if="get(subMenuItemStatus, sub_menu.name) && subMenuItemStatus[sub_menu.name].helpText"
                        >info_outline</md-icon
                      >

                      <b-form-checkbox
                        v-model="sub_menu.is_enable"
                        name="check-button"
                        switch
                        :disabled="get(subMenuItemStatus, sub_menu.name) && !subMenuItemStatus[sub_menu.name].enable"
                      >
                        Enabled?
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="card card-lg overlay--primary-dodger-blue mb-1">
              <div class="card-body d-flex flex-column">
                <div class="d-flex align-items-center">
                  <div class="col-8 col-md-8 d-flex">
                    <i class="fas fa-bars icon-16pt mr-3"></i>
                    <!-- <span v-if="get(getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN">
                      {{ menu.title }}
                    </span> -->
                    <!-- v-else -->
                    <editable-label
                      :inputLabel="menu.title"
                      v-model="menu.title"
                      @saveLabel="changeTitle(basicConfig.navMenu)"
                    />
                  </div>
                  <div class="col-4 col-md-4 d-flex justify-content-end">
                    <b-form-checkbox class="ml-auto" v-model="menu.is_enable" name="check-button" switch>
                      Enabled?
                    </b-form-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </b-form-group>
        </draggable>
      </b-form-group>
      <b-form-group label-class="form-label">
        <b-form-checkbox v-model="basicConfig.navBtn" name="check-nav-button" switch
          >Show Partnership button?</b-form-checkbox
        >
      </b-form-group>
      <div v-if="basicConfig.navBtn">
        <b-form-group label="Title" label-for="nav-button-title" label-class="form-label">
          <b-form-input id="nav-button-title" placeholder="Enter a title" v-model="basicConfig.navBtnTitle" />
        </b-form-group>

        <b-form-group label="Target Audience" label-for="nav-button" label-class="form-label">
          <div class="row">
            <b-form-group class="col-md-6" label-class="form-label">
              <b-form-checkbox v-model="basicConfig.isSchoolLink" name="check-nav-button" switch>
                <span
                  v-b-popover.hover.right
                  :title="
                    `User will be redirected to '${transitionUrl}/school-interest?organization=${organization.slug}'`
                  "
                >
                  Schools
                </span>
              </b-form-checkbox>
            </b-form-group>
            <b-form-group class="col-md-6" label-class="form-label">
              <b-form-checkbox v-model="basicConfig.isEmployerLink" name="check-nav-button" switch>
                <span
                  v-b-popover.hover.right
                  :title="
                    `User will be redirected to '${transitionUrl}/employer-signup/?org_refer_id=${organization.org_refer_id}'`
                  "
                >
                  Employers
                </span>
              </b-form-checkbox>
            </b-form-group>
          </div>
        </b-form-group>
      </div>

      <!-- <page-separator>
        Website Placeholders
        <md-icon
          style="font-size: 20px; color: grey"
          class="svg-icon mr-2"
          v-b-popover.hover.right
          title="Will be used in various places like classes section, etc."
          >info</md-icon
        >
      </page-separator>

      <b-form-group
        label="Default Image"
        label-for="default-image"
        label-class="form-label"
        class="row-align-items-center"
      >
        <b-media class="align-items-center" vertical-align="center">
          <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
            <span v-if="isImgUploading">...</span>
            <b-img
              :src="basicConfig.defaultImageUrl"
              class="img-fluid"
              width="40"
              alt="Image"
              v-else-if="basicConfig.defaultImageUrl"
            />
            <i class="fas fa-university fa-lg" v-else></i>
          </fmv-avatar>

          <b-form-file
            id="default-image"
            placeholder="Select Image"
            :browse-text="$t('generalMsgs.browse')"
            v-model="uploadFile.default_image"
            @input="uploadImage('default_image')"
            :disabled="isImgUploading"
            accept="image/*"
          />
        </b-media>
      </b-form-group> -->

      <page-separator title="Website Footer" />

      <b-form-group label="Logo" label-for="image" label-class="form-label" class="row-align-items-center">
        <b-media class="align-items-center" vertical-align="center">
          <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
            <span v-if="isFooterImgLoading">...</span>
            <b-img
              :src="basicConfig.footer_logo_url"
              class="img-fluid"
              width="40"
              alt="Image"
              v-else-if="basicConfig.footer_logo_url"
            />
            <i class="fas fa-university fa-lg" v-else></i>
          </fmv-avatar>

          <image-uploader
            @image="e => setImage(e, 'footer_image')"
            :isUploading="isFooterImgLoading"
            :isRequired="false"
            placeholder="Select Footer Logo"
          />
        </b-media>
      </b-form-group>

      <div class="row">
        <b-form-group
          v-for="(item, index) in basicConfig.footer"
          :key="`${index}-${item}`"
          label-class="form-label"
          class="col-6"
        >
          <b-form-checkbox v-model="item.is_enable" name="check-button" switch
            >{{ $t(`footer.${item.name}`) }}
          </b-form-checkbox>
        </b-form-group>
      </div>

      <b-form-group label="Footer Description" label-for="footer_desc" label-class="form-label">
        <b-form-textarea
          id="footer_desc"
          :rows="5"
          placeholder="An initiative powered by Transition supports individuals pursing a career as a certified nursing assistant by helping them easily find top rated programs in their location."
          v-model.trim="basicConfig.footer_desc"
        />
      </b-form-group>

      <b-form-group label="Social Links" label-for="name" label-class="form-label">
        <div class="row ml-1 mb-2">
          <b-icon class="mr-3 mt-2 text-primary" scale="1.8" icon="facebook" aria-hidden="true"></b-icon>
          <fmv-input-group-merge
            class="col-11 no-padding"
            id="name"
            placeholder=""
            v-model="basicConfig.footerFacebook"
            prepend
          >
            {{ basicConfig.facebookUrl }}
          </fmv-input-group-merge>
        </div>

        <div class="row ml-1 mb-2 text-primary">
          <b-icon class="mr-3 mt-2" scale="1.8" icon="instagram" aria-hidden="true"></b-icon>
          <fmv-input-group-merge
            class="col-11 no-padding"
            id="name"
            placeholder=""
            v-model="basicConfig.footerInstagram"
            prepend
          >
            {{ basicConfig.instagramUrl }}</fmv-input-group-merge
          >
        </div>

        <div class="row ml-1 mb-2 text-primary">
          <b-icon class="mr-3 mt-2" scale="1.8" icon="twitter" aria-hidden="true"></b-icon>
          <fmv-input-group-merge
            class="col-11 no-padding"
            id="name"
            placeholder=""
            v-model="basicConfig.footerTwitter"
            prepend
          >
            {{ basicConfig.twitterUrl }}
          </fmv-input-group-merge>
        </div>

        <div class="row ml-1 mb-2 text-primary">
          <b-icon class="mr-3 mt-2" scale="1.8" icon="youtube" aria-hidden="true"></b-icon>
          <fmv-input-group-merge
            class="col-11 no-padding"
            id="name"
            placeholder=""
            v-model="basicConfig.footerYoutube"
            prepend
          >
            {{ basicConfig.youtubeUrl }}
          </fmv-input-group-merge>
        </div>

        <div class="row ml-1 mb-2 text-primary">
          <b-icon class="mr-3 mt-2" scale="1.8" icon="linkedin" aria-hidden="true"></b-icon>
          <fmv-input-group-merge
            class="col-11 no-padding"
            id="name"
            placeholder=""
            v-model="basicConfig.footerLinkedin"
            prepend
          >
            {{ basicConfig.linkedinUrl }}
          </fmv-input-group-merge>
        </div>
      </b-form-group>

      <page-separator>
        Meta Descriptions
        <md-icon
          style="font-size: 20px; color: grey"
          class="svg-icon mr-2"
          v-b-popover.hover.right
          title="Will be used for SEO purposes"
          >info</md-icon
        >
      </page-separator>

      <b-form-group label="Trainings Page Description" label-for="home-desc" label-class="form-label">
        <b-form-textarea
          id="home-desc"
          placeholder="Enter description"
          rows="3"
          maxlength="200"
          v-model="meta.training_meta_desc"
        ></b-form-textarea>
      </b-form-group>

      <b-form-group label="Programs Page Description" label-for="about-desc" label-class="form-label">
        <b-form-textarea
          id="about-desc"
          placeholder="Enter description"
          rows="3"
          maxlength="200"
          v-model="meta.prg_meta_desc"
        ></b-form-textarea>
      </b-form-group>

      <b-form-group label="Sponsorships Page Description" label-for="gallery-desc" label-class="form-label">
        <b-form-textarea
          id="gallery-desc"
          placeholder="Enter description"
          rows="3"
          maxlength="200"
          v-model="meta.spnsr_meta_desc"
        ></b-form-textarea>
      </b-form-group>

      <b-form-group label="Employers Page Description" label-for="faq-desc" label-class="form-label">
        <b-form-textarea
          id="faq-desc"
          placeholder="Enter description"
          rows="3"
          maxlength="200"
          v-model="meta.emp_meta_desc"
        ></b-form-textarea>
      </b-form-group>

      <b-form-group label="Jobs Page Description" label-for="tuition-desc" label-class="form-label">
        <b-form-textarea
          id="tuition-desc"
          placeholder="Enter description"
          rows="3"
          maxlength="200"
          v-model="meta.jobs_meta_desc"
        ></b-form-textarea>
      </b-form-group>

      <b-btn variant="primary" :disabled="isFormLoading" style="width: 150px" type="submit" class="btn-normal">
        <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
        <span v-else>Update</span>
      </b-btn>
    </b-form>
    <confirm-modal
      :showModal="showConfirmModal"
      :value="sponsored_programs"
      @update="updateConfirmation"
      @close="hideConfirmModal"
    />
  </div>
</template>

<style lang="css" scoped>
/* @import './style.css'; */
</style>

<script>
import { get, isEmpty, replace } from 'lodash';
import axios from 'axios';
import { mapGetters, mapActions } from 'vuex';
import { FmvAvatar } from 'fmv-avatar';
import Page from '@/components/Page.vue';
import EditableLabel from '@/components/EditableLabel.vue';
import { USER_ROLES, USER_ROLE_TYPES } from '@/common/constants';
import { requiredIf } from 'vuelidate/lib/validators';
import draggable from 'vuedraggable';
import ConfirmModal from './ConfirmModal.vue';

import PageHeader from '@/components/Ui/PageHeader.vue';
import ImageUploader from '@/components/ImageUploader.vue';
import { FmvInputGroupMerge } from 'fmv-input-group-merge';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import MdIcon from '@/components/Ui/MdIcon.vue';

export default {
  components: {
    draggable,
    PageHeader,
    FmvAvatar,
    EditableLabel,
    ConfirmModal,
    ImageUploader,
    FmvInputGroupMerge,
    PageSeparator,
    MdIcon
  },
  extends: Page,
  data() {
    return {
      title: 'Configure Basic Website Settings',
      isAdded: false,
      org: null,
      organization: {},
      options: [
        { text: 'Associated with Offered Sponsorships', value: true },
        { text: 'All', value: false }
      ],
      showTrainProvidersOptions: [
        { text: 'Yes', value: true },
        { text: 'No', value: false }
      ],
      dragging: false,
      sponsored_programs: false,
      show_schls_on_site: true,
      showConfirmModal: false,
      meta: {
        training_meta_desc: '',
        prg_meta_desc: '',
        spnsr_meta_desc: '',
        emp_meta_desc: '',
        jobs_meta_desc: ''
      },
      basicConfig: {
        navMenu: [
          { name: 'training_providers', is_enable: true, title: 'Training Providers' },
          // { name: 'all_programs', is_enable: false, title: 'Programs' },
          { name: 'all_jobs', is_enable: false, title: 'Jobs' },
          { name: 'all_employers', is_enable: false, title: 'Employers' },
          { name: 'all_sponsorships', is_enable: false, title: 'Sponsorships' },
          { name: 'about', is_enable: false, title: 'About' },
          { name: 'all_events', is_enable: false, title: 'Events' },
          { name: 'career_center', is_enable: true, title: 'Career Center' },
          {
            name: 'find_training',
            is_enable: false,
            title: 'Find Training',
            sub_menus: [
              { name: 'all_programs', is_enable: false },
              { name: 'upcoming_classes', is_enable: false }
            ]
          },
          {
            name: 'careers',
            is_enable: false,
            title: 'Careers',
            sub_menus: [
              { name: 'resume_builder', is_enable: false },
              { name: 'career_assessment', is_enable: false },

              { name: 'career_pathways', is_enable: false }
            ]
          }
        ],

        navBtn: false,
        navBtnTitle: '',
        isSchoolLink: false,
        isEmployerLink: false,

        footer: [
          { name: 'phone', is_enable: true },
          { name: 'email', is_enable: true },
          { name: 'address', is_enable: true }
          // { name: 'website', is_enable: true },
        ],
        tuition_reg_req: false,
        logo_url: null,
        defaultImageUrl: null,
        footer_logo_url: null,
        footer_desc: null,
        theme: '',
        color: null,

        footerFacebook: null,
        footerYoutube: null,
        footerLinkedin: null,
        footerTwitter: null,
        footerInstagram: null,

        facebookUrl: 'https://www.facebook.com/',
        instagramUrl: 'https://www.instagram.com/',
        twitterUrl: 'https://twitter.com/',
        linkedinUrl: 'https://www.linkedin.com/',
        youtubeUrl: 'https://www.youtube.com/'
      },
      areOrgsLoading: false,
      allOrgs: [],
      isLoading: false,
      isImgUploading: false,
      isFooterImgLoading: false,
      typeOptions: [
        { value: 'agency', text: 'Agency' },
        { value: 'medical', text: 'Medical' }
      ],
      colorTypeOptions: [
        { value: 'light', text: 'Light' },
        { value: 'dark', text: 'Dark' }
      ],
      uploadFile: { image: null, footer_image: null, default_image: null },
      uploadPercent: { image: 0, footer_image: 0, default_image: 0 },
      uploadCancelTokenSource: { image: null, footer_image: null, default_image: null },
      USER_ROLES,
      USER_ROLE_TYPES,
      transitionUrl: process.env.VUE_APP_MAIN_DOMAIN,
      font: 'Inter+Roboto',
      allFonts: [
        { value: 'Inter+Roboto', text: 'Inter + Roboto' },
        { value: 'Roboto+Poppins', text: 'Roboto + Poppins' },
        { value: 'Arvo+Lato', text: 'Arvo + Lato' },
        { value: 'Merriweather+Mulish', text: 'Merriweather + Mulish' },
        { value: 'Work Sans+Open Sans', text: 'Work Sans + Open Sans' },
        { value: 'Oswald+Quattrocento', text: 'Oswald + Quattrocento' },
        { value: 'Ovo+Quattrocento', text: 'Ovo + Quattrocento' },
        { value: 'Ubuntu+Open Sans', text: 'Ubuntu + Open Sans' },
        { value: 'Slabo 13px+Lato', text: 'Slabo 13px + Lato' },
        { value: 'Rufina+PT Serif', text: 'Rufina + PT Serif' }
      ]
    };
  },
  validations() {
    return {
      org: {
        required: requiredIf(() => get(this.getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN)
      }
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    ...mapGetters('payoutAcc', ['getPayoutAccountStatus']),
    isFormLoading() {
      return this.isLoading || this.isImgUploading || this.areSchoolsLoading || this.isFooterImgLoading;
    },
    subMenuItemStatus() {
      return {
        upcoming_classes: !this.show_schls_on_site
          ? { enable: false, helpText: "Enable 'Show Training Providers on Site' to enable this option." }
          : null
      };
    }
  },

  watch: {
    'basicConfig.navBtn': {
      handler(value) {
        if (value) {
          this.basicConfig.isEmployerLink = true;
          this.basicConfig.isSchoolLink = true;
        }
      }
    },
    'basicConfig.isEmployerLink': 'updateNavBarCtaBtn',
    'basicConfig.isSchoolLink': 'updateNavBarCtaBtn'
  },

  methods: {
    ...mapActions('s3Upload', ['uploadToPresignedUrl']),
    ...mapActions('organization', [
      'getAllOrganizations',
      'getOrganization',
      'createBasicConfig',
      'getBasicConfig',
      'getOrgImageUploadPresignedUrl',
      'updateNavbarMenuLabel'
    ]),
    setImage(file, image) {
      this.uploadFile[image] = file;
      this.uploadImage(image);
    },
    openConfirmModal() {
      this.showConfirmModal = true;
    },
    onShowSchoolsChange(currValue) {
      if (!currValue) {
        const findTrainingItem = this.basicConfig.navMenu.find(menu => menu.name === 'find_training');
        if (findTrainingItem) {
          const classesItem = findTrainingItem.sub_menus.find(subMenu => subMenu.name === 'upcoming_classes');
          if (classesItem) {
            classesItem.is_enable = false;
          }
        }
      }
    },
    hideConfirmModal() {
      this.showConfirmModal = false;
    },
    updateConfirmation() {
      this.sponsored_programs = !this.sponsored_programs;
      this.hideConfirmModal();
    },
    updateNavBarCtaBtn() {
      if (!this.basicConfig.isEmployerLink && !this.basicConfig.isSchoolLink) {
        this.basicConfig.navBtn = false;
      }
    },
    get,
    isEmpty,
    async changeTitle(navMenu) {
      try {
        const data = {
          navbar_attribs: navMenu,
          ...(get(this.getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN && { org_id: this.org })
        };
        await this.updateNavbarMenuLabel(data);
        this.makeToast({ variant: 'success', msg: 'Navbar Menu Title Updated!' });
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    },
    async updateOrder() {
      // this.dragging = false;
      // const data = {
      //   custom_navbar_menu: map(this.items, (id, order_num) => ({
      //     id: id.id,
      //     order_num: order_num + 1,
      //   })),
      // };
      // try {
      //   await this.updateCustomNavbarItemOrder(data);
      // } catch (err) {
      //   this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      // }
    },
    resetData() {
      this.basicConfig.theme = null;
      this.basicConfig.color = null;

      this.basicConfig.footer_desc = null;
      this.basicConfig.logo_url = null;
      this.basicConfig.defaultImageUrl = null;
      this.basicConfig.navMenu = null;
      this.basicConfig.footer = null;
      this.basicConfig.footerTwitter = null;
      this.basicConfig.footerInstagram = null;
      this.basicConfig.footerYoutube = null;
      this.basicConfig.footerLinkedin = null;
      this.basicConfig.footerFacebook = null;
      this.meta.training_meta_desc = '';
      this.meta.prg_meta_desc = '';
      this.meta.spnsr_meta_desc = '';
      this.meta.emp_meta_desc = '';
      this.meta.jobs_meta_desc = '';

      // this.basicConfig.school = null;
    },
    prefillLogo(orgId) {
      this.resetData();
      this.fetchBasicConfig(orgId);
    },
    async fetchOrgs() {
      this.areOrgsLoading = true;
      const response = await this.getAllOrganizations({});
      this.allOrgs = response.data;

      this.areOrgsLoading = false;
    },
    async fetchBasicConfig(org_id = null) {
      let resp;
      if (org_id) {
        resp = await this.getBasicConfig({
          org_id: org_id
        });
      } else {
        resp = await this.getBasicConfig({});
      }

      this.basicConfig.logo_url = resp.navbar_logo;
      // this.basicConfig.defaultImageUrl = resp.default_img_url;
      this.basicConfig.footer_logo_url = resp.footer_logo;
      this.font = resp.font_option;
      this.basicConfig.navMenu = resp.navbar_attribs;
      this.basicConfig.footer = resp.footer_contact_attribs;
      this.basicConfig.footer_desc = resp.footer_desc;
      this.sponsored_programs = resp.sponsored_programs;
      this.show_schls_on_site = resp.show_schls_on_site;
      this.basicConfig.footerTwitter = replace(resp.twitter_url, this.basicConfig.twitterUrl, '');
      this.basicConfig.footerInstagram = replace(resp.insta_url, this.basicConfig.instagramUrl, '');
      this.basicConfig.footerYoutube = replace(resp.youtube_url, this.basicConfig.youtubeUrl, '');
      this.basicConfig.footerLinkedin = replace(resp.linkedin_url, this.basicConfig.linkedinUrl, '');
      this.basicConfig.footerFacebook = replace(resp.fb_url, this.basicConfig.facebookUrl, '');

      this.basicConfig.navBtn = resp.navbar_btn;
      this.basicConfig.navBtnTitle = resp.navbar_btn_title;
      this.basicConfig.isSchoolLink = resp.sch_link_enable;
      this.basicConfig.isEmployerLink = resp.emp_link_enable;

      this.organization = resp.organization;

      this.meta.training_meta_desc = resp.training_meta_desc;
      this.meta.prg_meta_desc = resp.prg_meta_desc;
      this.meta.spnsr_meta_desc = resp.spnsr_meta_desc;
      this.meta.emp_meta_desc = resp.emp_meta_desc;
      this.meta.jobs_meta_desc = resp.jobs_meta_desc;
    },
    async uploadImage(uploadType) {
      this.uploadCancelTokenSource[uploadType] = axios.CancelToken.source();
      this.isImgUploading = uploadType === 'image';

      try {
        const urlResp = await this.getOrgImageUploadPresignedUrl({
          file_name: this.uploadFile[uploadType].name,
          content_type: this.uploadFile[uploadType].type,
          upload_type: 'images'
        });
        await this.uploadToPresignedUrl({
          url: urlResp.upload_url,
          file: this.uploadFile[uploadType],
          config: {
            onUploadProgress: function(progressEvent) {
              this.uploadPercent[uploadType] = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            }.bind(this),
            cancelToken: this.uploadCancelTokenSource[uploadType].token
          }
        });
        if (uploadType === 'image') {
          this.basicConfig.logo_url = urlResp.upload_url.split('?')[0];
        } else if (uploadType === 'footer_image') {
          this.basicConfig.footer_logo_url = urlResp.upload_url.split('?')[0];
        } else if (uploadType === 'default_image') {
          this.basicConfig.defaultImageUrl = urlResp.upload_url.split('?')[0];
        }
      } catch (error) {
        this.uploadFile[uploadType] = null;
      }

      this.uploadCancelTokenSource[uploadType] = null;
      this.uploadPercent[uploadType] = 0;

      if (uploadType === 'image') this.isImgUploading = false;
    },

    async onSubmit() {
      this.isLoading = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          const data = {
            wbst_theme: this.basicConfig.theme,
            wbst_scheme: this.basicConfig.color,
            sponsored_programs: this.sponsored_programs,
            show_schls_on_site: this.show_schls_on_site,
            navbar_logo: this.basicConfig.logo_url,
            default_img_url: this.basicConfig.defaultImageUrl,
            navbar_attribs: this.basicConfig.navMenu,
            footer_logo: this.basicConfig.footer_logo_url,
            footer_contact_attribs: this.basicConfig.footer,
            footer_desc: this.basicConfig.footer_desc,
            twitter_url: this.basicConfig.footerTwitter
              ? this.basicConfig.twitterUrl + this.basicConfig.footerTwitter
              : null,
            insta_url: this.basicConfig.footerInstagram
              ? this.basicConfig.instagramUrl + this.basicConfig.footerInstagram
              : null,
            youtube_url: this.basicConfig.footerYoutube
              ? this.basicConfig.youtubeUrl + this.basicConfig.footerYoutube
              : null,
            linkedin_url: this.basicConfig.footerLinkedin
              ? this.basicConfig.linkedinUrl + this.basicConfig.footerLinkedin
              : null,
            fb_url: this.basicConfig.footerFacebook
              ? this.basicConfig.facebookUrl + this.basicConfig.footerFacebook
              : null,

            training_meta_desc: this.meta.training_meta_desc,
            prg_meta_desc: this.meta.prg_meta_desc,
            spnsr_meta_desc: this.meta.spnsr_meta_desc,
            emp_meta_desc: this.meta.emp_meta_desc,
            jobs_meta_desc: this.meta.jobs_meta_desc,

            navbar_btn: this.basicConfig.navBtn,
            navbar_btn_title: this.basicConfig.navBtnTitle,
            sch_link_enable: this.basicConfig.isSchoolLink,
            emp_link_enable: this.basicConfig.isEmployerLink,
            font_option: this.font,

            organization:
              get(this.getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN
                ? this.org
                : this.getLoggedInUser.linked_entity.id
          };

          await this.createBasicConfig({
            ...data
          });
          this.makeToast({ variant: 'success', msg: 'Settings Configured!' });
        } catch (err) {
          this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        }
      } else {
        this.makeToast({ variant: 'danger', msg: 'Please fill all fields correctly.' });
      }
      this.isLoading = false;
    }
  },

  async mounted() {
    this.isLoading = true;
    if (get(this.getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN) {
      this.fetchOrgs();
    } else if (get(this.getLoggedInUser, 'role') === USER_ROLES.ORGANIZATION) {
      (await this.getOrganization(this.getLoggedInUser.linked_entity.id)).data;
    }
    try {
      if (get(this.getLoggedInUser, 'role') === USER_ROLES.ORGANIZATION) {
        this.fetchBasicConfig();
      }
    } catch (e) {
      this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
    }
    this.isLoading = false;
  }
};
</script>

<style lang="scss" scoped>
.px-4-lg {
  @media (min-width: 450px) {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}
</style>
